<template>
  <div class="container c-690">
    <b-overlay
      :show="ShowRound"
      variant="transparent"
      opacity="0.99"
      blur="5px"
      rounded="sm"
    >
      <template #overlay>
        <div class="text-center">
          <b-icon-controller
            font-scale="3"
            animation="cylon"
          />
          <p id="cancel-label">
            กรุณารอสักครู่...
          </p>
        </div>
      </template>
      <!-- <iframe
        ref="iFrameGame"
        frameborder="0"
        allowfullscreen
        style="width:100%;height:100vh; position:relative;border:0px;outline:none;overflow:hidden;margin:0px;padding:0px;"
        onload="ResizeIFrame()"
        :src="
          `https://gwc788.net/playGame?token=${token}&appID=F7BY&gameCode=${GameCode}&language=en&mobile=false&redirectUrl=https://wallet.hitler168.com/games`
        "
      /> -->
      <iframe
        v-if="Url"
        ref="iFrameGame"
        frameborder="0"
        allowfullscreen
        style="width:100%;height:100vh; position:relative;border:0px;outline:none;overflow:hidden;margin:0px;padding:0px;"
        onload="ResizeIFrame()"
        :src="Url"
      />
    </b-overlay>
  </div>
</template>

<script>
import {
  // BRow,
  // BCol,
  BOverlay,
  BIconController,
  // BBadge,
  // BInputGroup,
  // BInputGroupPrepend,
  // BButton,
  // BFormInput,
} from 'bootstrap-vue'
// import momenttz from 'moment-timezone'
// import ML from '../rule/ml.vue'
// import THG from '../rule/thg.vue'
// import GSB from '../rule/gsb.vue'
// import TKS from '../rule/tks.vue'
// import YG from '../rule/yg.vue'
// import DE from '../rule/de.vue'
// import GB from '../rule/gb.vue'
// import LA from '../rule/la.vue'
// import JP from '../rule/jp.vue'
// import SG from '../rule/sg.vue'
// import TW from '../rule/tw.vue'
// import CN from '../rule/cn.vue'
// import IN from '../rule/in.vue'
// import RU from '../rule/ru.vue'
// import VN from '../rule/vn.vue'
// import EG from '../rule/eg.vue'
// import KR from '../rule/kr.vue'
// import HK from '../rule/hk.vue'
// import THZ from '../rule/thz.vue'

export default {
  components: {
    // BRow,
    // BCol,
    BOverlay,
    BIconController,
    // ML,
    // THG,
    // TKS,
    // YG,
    // EG,
    // DE,
    // GB,
    // LA,
    // SG,
    // TW,
    // CN,
    // JP,
    // IN,
    // RU,
    // VN,
    // GSB,
    // HK,
    // THZ,
    // KR,
    // BBadge,
    // BInputGroup,
    // BInputGroupPrepend,
    // BButton,
    // BFormInput,
  },
  data() {
    return {
      rule: null,
      Test: false,
      ShowRound: true,
      message: '',
      GetRoundInterval: null,
      Interval: null,
      Items: [],
      SubItems: [],
      AgentData: null,
      userData: JSON.parse(localStorage.getItem('userData')),
      Url: null,
    }
  },
  // beforeDestroy() {
  //   clearInterval(this.Interval)
  //   clearInterval(this.GetRoundInterval)
  // },
  async mounted() {
    window.addEventListener(
      'message',
      e => {
        if (e == null || e.data == null) {
          return
        }
        // eslint-disable-next-line default-case
        switch (e.data.event) {
          case 'ReadyToPlay':
            this.ResizeIFrame()
            break
        }
      },
      false,
    )
    this.LoginLotto()
  },
  methods: {
    LoginLotto() {
      this.$http
        .post('https://lineapi.rmtlotto.com/api/lotto/login')
        .then(response => {
          // console.log(response.data)
          this.ShowRound = false
          this.Url = response.data.url
          // window.location.href = response.data.url
        })
        .catch(error => console.log(error))
    },
    ResizeIFrame() {
      this.$ref.iFrameGame.contentWindow.postMessage(
        {
          event: 'iFrameSize',
          innerWidth: window.innerWidth,
          clientWidth: document.body.clientWidth,
          innerHeight: window.innerHeight,
          clientHeight: document.body.clientHeight,
        },
        '*',
      )
    },
    contactus() {
      // window.location.href = 'https://line.me/R/ti/p/@920cvvmz'
    },
    Commas(x) {
      if (x) {
        if (Math.round(x) !== x && x > 0) {
          const Num = Number(x)
          const y = Num.toFixed(2)
          return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        }
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return 0
    },
    showToast(Mes) {
      this.$toast.error(Mes, {
        position: 'top-right',
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: 'button',
        icon: 'fa fa-bell',
        rtl: false,
      })
    },
  },
}
</script>

<style scoped>
/* * {
  padding: 0;
} */
.pad-inner {
  padding: 20px 18px 100px;
}
.ico {
  margin-top: 9px;
  margin-left: 9px;
}
.card-go {
  background: rgba(255, 255, 255, 0.822);
  height: 32px;
  width: 32px;
  align-items: center;
  border-radius: 6px;
  margin-right: 10px;
}
.colum {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.boxhuay.disabled {
  filter: grayscale(1) opacity(0.7);
}
.boxhuay {
  width: 100%;
  font-family: 'Mitr', sans-serif;
  display: block;
  margin: 0 auto;
  background: #fff;
  border-radius: 3px;
  overflow: hidden;
  /* border: 1px solid rgba(0, 0, 0, 0.5); */
}
a .lotto-card {
  border: 2px solid #fff;
  font-family: 'Mitr', sans-serif;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 10px hsl(0deg 0% 100% / 50%);
}
.goldpattern {
  background: url(/images/bg-header.svg) top;
  background-size: 100%;
}
.goldpattern2 {
  background: url(/images/gold.jpg) top;
  background-size: 100%;
}
.head-huay {
  width: 100%;
  height: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
}
.goldpattern {
  background-color: #052d2f;
}
.align-items-stretch {
  align-items: stretch !important;
}
.flex-fill {
  flex: 1 1 auto !important;
}
.headtxt {
  text-align: right;
  color: #fff;
  text-shadow: #000 2px 0 0, #000 1.75517px 0.958851px 0,
    #000 1.0806px 1.68294px 0, #000 0.141474px 1.99499px 0,
    #000 -0.832294px 1.81859px 0, #000 -1.60229px 1.19694px 0,
    #000 -1.97998px 0.28224px 0, #000 -1.87291px -0.701566px 0,
    #000 -1.30729px -1.5136px 0, #000 -0.421592px -1.95506px 0,
    #000 0.567324px -1.91785px 0, #000 1.41734px -1.41108px 0,
    #000 1.92034px -0.558831px 0;
  letter-spacing: 0.04em;
}
.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
  font-size: 1.4rem;
}
.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}
.subhead-huay {
  display: block;
  padding: 2px 2px 1px;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: gold;
  text-shadow: #000 1px 1px 2px;
  font-size: 80%;
}
.date-huay {
  display: block;
  padding: 2px 2px 1px;
  width: 100%;
  background: rgba(166, 12, 12, 0.5);
  text-shadow: #000 1px 1px 2px;
}
.body-huay {
  width: 100%;
  display: block;
  background: #252525;
  text-align: right;
  color: #fff;
  padding: 8px 5px;
  font-size: 18px;
}
.bg-warning {
  background-color: #ff293e !important;
}
.headflag > span {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}
.headflag {
  width: 50px;
  overflow: hidden;
  align-self: stretch;
  border-right: 1px solid rgba(0, 0, 0, 0.5);
}
.fullflag {
  background-size: cover;
  height: 100%;
  width: 100%;
}
.lotto-head.lotto-yeekee[data-v-97f300d0] {
  background-color: #ce0a1e;
  border: 1px solid #aa1423;
}
.withdraw {
  max-width: 640px;
  margin: auto;
}
.depo {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  border-radius: 10px;
  padding: 22px;
  background-color: #fff;
  box-shadow: 2px 2px 10px rgb(212, 212, 212);
}

.btn {
  /* border-radius: 38px; */
  color: rgb(0, 0, 0);
  background-color: #ffc800;
}
.backg {
  background-color: #fff;
  padding: 10px;
  margin-bottom: 15px;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 15px;
  font-family: 'Mitr', sans-serif;
  box-shadow: 2px 2px 10px rgb(212, 212, 212);
}
.lotto {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  padding: 8px;
  background: #272727;
  border-radius: 5px 5px 0px 0px;
  font-weight: 500;
  font-size: 1.4rem;
  color: #fff;
  box-shadow: 2px 2px 10px rgb(212, 212, 212);
}
.lotto-card2 {
  font-family: 'Mitr', sans-serif;
  /* border: 2px solid white; */
  border-radius: 12px;
  /* overflow: hidden; */
  /* box-shadow: rgb(255 255 255 / 50%) 0 0 10px; */
}
.lotto-card2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.huay-card-bg-yk {
  background: #f7bf46;
  color: #000000;
}
.huay-card-header {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
  padding: 12px 6px;
  border-radius: 9px 9px 0px 0px;
  background: linear-gradient(180deg, #d66d43 0%, #d61c56 100%);
}
.huay-card-icon {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  margin-top: 5px;
}

img {
  vertical-align: middle;
  border-style: none;
}
.huay-card-product-yk {
  border-radius: 7px;
  flex: 1;
  background-color: #fde14c;
}
.huay-card-body {
  background-color: #e2e3e4;
  width: 100%;
  padding: 6px;
  border-radius: 0px 0px 10px 10px;
}
.huay-card-body-content {
  background-color: #ffffff;
  color: var(--text-primary-color);
  width: 100%;
  border-radius: 10px;
  padding: 2px 3px;
}
.lotto-time {
  width: 100%;
  display: flex;
  /* border-bottom-left-radius: 5px; */
  /* border-bottom-right-radius: 5px; */
  /* padding: 5px; */
  background: white;
  /* border: #ccc 1px solid; */
  /* border-top: none; */
  text-align: right;
  font-size: 16px;
  line-height: 30px;
  color: #bbb;
  flex-direction: row;
  justify-content: space-between;
}
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
.huay-card-product-name {
  font-size: 1.2rem;
  font-weight: 700;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* height: 100%; */
  text-align: center;
  padding: 0px 1px;
  /* margin-top: 1px; */
  color: #fff;
}
.huay-card-period {
  float: right;
  background: white;
  color: #000;
  width: 95%;
  border-radius: 7px;
  text-align: center;
  font-size: 0.9rem;
  margin: 7px 5px;
}
</style>
